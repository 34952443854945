import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Loading from './Loading'
import { NumericFormat } from 'react-number-format';

const ProductView = () => {
  const {id} = useParams()
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)
  const [iso, setIso] = useState('');

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://api.hidrogen.id/api/posts/${id}`);
        setProduct(response.data);
        setIso(new Date(response.data.publishedAt));
      } catch (error) {
        console.error("Error fetching the product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        product ? (
          <div className='article-container'>
            <h1>{product.title}</h1>
            <h4>
              Published On: {iso.toLocaleDateString()} at {iso.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true,  })} <br /> <br />
            </h4>
            <img className='article-image' src={'https://api.hidrogen.id/uploads/'+product.imageUrl} alt={product.title} />
            <h3 className="price"><NumericFormat value={product.price} displayType={'text'} thousandSeparator={true} prefix={'IDR'} /></h3>
            <p>
              {product.summary}<br /><br />
              <a href={'https://api.whatsapp.com/send?phone=628888999966&amp;text=Assalam mualaikum, saya tertarik dengan '+product.title}>Order via WhatsApp</a><br /> <br />
            </p>
          </div>
        ) : (
          <div>
            <p>Couldn't fetch data</p>
          </div>
        )
      )}
    </>
  );
};

export default ProductView;