import React from "react";
import logo from "../assets/logo.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <a href="/"><div className="navbar-head"><img src={logo} alt="Jadoel" /></div></a>
      {/* <ul className="navbar-elements">
        <h2><a  href="/">Home</a> </h2>
      </ul> */}
    </div>
  );
};

export default Navbar;