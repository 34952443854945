import React from "react";
import { Link } from "react-router-dom";

const ProductTile = ({ products }) => {
  return (
    <>
      {products.map((product) => (
        <Link to={`/product/${product.id}`} key={product.id} className="tile_box">
          <img className="tile_img" src={'https://api.hidrogen.id/uploads/'+product.imageUrl} alt={product.title}/>
          <div className="tile_text">
            <h2>{product.title}</h2>
            <p>{product.summary.slice(0, 160)}</p>
          </div>
        </Link>
      ))}
    </>
  );
};

export default ProductTile;