import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import ProductTile from "./ProductTile";
import Loading from "./Loading";

const Dashboard = () => {
  const [products, seProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://api.hidrogen.id/api/posts")
    .then((response) => {
      seProducts(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching products:", error);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboard_tiles">
          <ProductTile products={products} />
        </div>
      )}
    </>
  );
};

export default Dashboard;