import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";
import ProductView from "./components/ProductView";

function App() {
  return (
    <>
    <Navbar/>
    <BrowserRouter>
     <Routes>
      <Route path="/" element={<Dashboard/>}></Route>
      <Route path="product/:id" element={<ProductView/>}></Route>
     </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;